<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>买入仓单</p>
      </div>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="commodity" label="品名"></el-table-column>
        <el-table-column prop="name" label="仓库名称"></el-table-column>
        <el-table-column prop="warehousCode" label="仓储编号"></el-table-column>
        <el-table-column prop="quantity" label="数量"></el-table-column>
        <el-table-column prop="specifications" label="规格"></el-table-column>
        <el-table-column prop="variety" label="品种"></el-table-column>
        <el-table-column prop="weight" label="重量"></el-table-column>
        <el-table-column prop="putWarehousTime" label="预计入库时间">
          <template slot-scope="scope">
            {{ scope.row.putWarehousTime | DateFormateDay }}
          </template>
        </el-table-column>
        <el-table-column prop="realPutWarehousTime" label="实际入库时间">
          <template slot-scope="scope">
            {{ scope.row.realPutWarehousTime | DateFormate }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="仓单状态">
          <template slot-scope="scope">
            {{ scope.row.fstatus | firmHoldStatusFormate }}
          </template>
        </el-table-column>
        <el-table-column prop label="操作" width="90">
          <template slot-scope="scope">
            <!-- <el-button @click="viewDialog(scope.row)" size="mini">查看</el-button> -->
            <!-- <el-button @click="download(scope.row)" type="danger" size="mini">下载</el-button> -->
            <el-button v-if="right.delivery" :disabled="scope.row.fstatus != 0 || scope.row.cwStatus != 0" @click="checkout(scope.row)" type="warning" size="mini">出库</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
    <!-- 出库 -->
    <deliveryDialog ref="deliveryDialog" :source="0" bsFlag="B" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import deliveryDialog from '../../../sellerCenter/salesMgt/depositOrder/deliveryDialog'
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        name: null,
        warehouse: null
      },
      dialogVisible: false,
      delivery: {
        warehousId: null,
        type: 0
      },
      source: 0,
      right: {
        delivery: false
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    deliveryDialog
  },
  mounted() {
    this.getDatas();
    this.getRights();
  },
  methods: {
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.delivery = this.isHasRight('trade-delivery-warehous');
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryWarehousSellerBuyer.param.page = this.current - 1;
      protocolFwd.param_queryWarehousSellerBuyer.param.size = this.pageSize;
      protocolFwd.param_queryWarehousSellerBuyer.param.type = 0;
      protocolFwd.param_queryWarehousSellerBuyer.param.status = null;
      protocolFwd.param_queryWarehousSellerBuyer.param.fstatus = null;
      protocolFwd.param_queryWarehousSellerBuyer.param.wfirmId = null;
      http.postFront(protocolFwd.param_queryWarehousSellerBuyer).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    viewDialog(row) {},
    download(row) {},
    checkout(row) {
      this.$refs.deliveryDialog.showDialog(row);
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
.deliveryContent {
  line-height: 30px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
