<template>
  <!-- 出库 -->
  <el-dialog
  v-dialogDrag
    title="请选择提货方式"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="500px"
    :before-close="handleClose">
    
    <el-form ref="form" :rules="rules" :model="form" label-width="130px">
      <el-form-item label="提货方式" prop="type">
        <el-radio-group v-model="form.type" @change="clearValidate()">
          <el-radio :label="1">物流</el-radio>
          <el-radio :label="0">自提</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="logistics">
        <!-- <el-form-item v-if="form.type == 0" label="交收仓库">
          <el-input :disabled="true" v-model="form.warehouse"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 0" label="仓库地址">
          <el-input :disabled="true" v-model="form.warehouseAddress"></el-input>
        </el-form-item> -->
        <el-form-item v-if="form.type == 0" label="提货车牌号" prop="carCode">
          <el-input v-model="form.carCode"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 0" label="提货人" prop="consignee">
          <el-input v-model="form.consignee"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 0" label="提货人电话" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 0" label="提货人身份证号" prop="papers">
          <el-input v-model="form.papers"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 0" label="提货时间" prop="pickupTime">
          <el-date-picker
            class="width-100"
            v-model="form.pickupTime"
            type="date"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>
      </div>
      <div class="selfMention">
        <el-form-item v-if="form.type == 1" label="物流公司" prop="cbLogisticsCompany">
          <el-select v-model="form.cbLogisticsCompany" class="width-100">
            <el-option
              v-for="item in companyOptions"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交收重量" prop="deliveryWeight">
          <el-input v-model="form.deliveryWeight"  :disabled="source==1">
            <template slot="append">{{ unit }}</template>
          </el-input>
        </el-form-item>
        <el-form-item  label="数量" prop="quantity">
          <el-input v-model="form.quantity" :disabled="source==1"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 1" label="收货人" prop="consignee">
          <el-input v-model="form.consignee"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 1" label="收货人电话" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 1" label="收货人地址" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 1" label="运费">
          <span>货到付款</span>
        </el-form-item>
        <el-form-item label="温馨提示" class="delivertip">
          <span>请尽可能出库当天提交出库申请，如提前申请，超过申请日期产生的仓储费由仓库单独收取！</span>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="goDelivery()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    source: {
      type: Number,
      default: function() {
        return [];
      }
    },
    bsFlag: {
      type: String,
      default: function() {
        return ""
      }
    },
    deliveryType:{
      type: String,
      default: function() {
        return ""
      }
    }
  },
  data() {
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      //  if(value==""){
      //     callback(new Error('请输入数量'));
      //  }
      // else 
      if(value){
          if (!reg.test(value)) { 
          return callback(new Error('请输入正整数'));
        }else{
          callback()
        }
      }else{
          callback()
      }
    
    };
    var checkPutHoldWeight = (rule, value, callback) => {
      let reg=/^\d+(\.\d{0,2})?$/;
      if (value=='') {
        return callback(new Error("请输入重量"));
      }
      else if (!reg.test(value)||Number(value)<=0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else if(Number(value)>Number(this.maxDeliveryWeight)){
          return callback(new Error("超过可交收重量"));
      }else {
        callback()
      }
    };
    return {
      dialogVisible: false,
      form: {
        warehousId: null,
        type: 1,//提货类型0：自提1：物流
        cbLogisticsCompany: null,
        consignee: null,
        phone: null,
        address: null,
        carCode: null,
        papers: null,
        pickupTime: null,
        appHoldId:null,//申请持仓id
        deliveryWeight:null,// 交收重量
        holdId:null,// 持仓id
        quantity:null,// 数量
        // source:null,// 来源：0：仓单1：申请仓单
      },
      rules: {
        type: [
          { required: true, message: "请选择提货方式", trigger: "change" }
        ],
        cbLogisticsCompany: [{ required: true, message: "请输入物流公司", trigger: "change" }],
        consignee: [
          { required: true, message: "请输入接收人", trigger: "change" }
        ],
        phone: [
          { required: true, message: "请输入电话", trigger: "change" }
        ],
        address: [
          { required: true, message: "请输入地址", trigger: "change" }
        ],
        carCode: [
          { required: true, message: "请输入提货车牌号", trigger: "change" }
        ],
        papers: [
          { required: true, message: "请输入身份证号", trigger: "change" }
        ],
        pickupTime: [
          { required: true, message: "请选择提货时间", trigger: "change" }
        ],
        quantity: [
          { required: false, message: "请输入交收数量", trigger: "change" },
             { validator: checkAmount, trigger: 'change' }
        ],
        deliveryWeight: [
          { required: true, message: "请输入交收重量", trigger: "change" },
             { validator: checkPutHoldWeight, trigger: 'change' }
        ]
      },
      companyOptions: [],
      isAddLoading: false,
      unit: null,
      maxDeliveryWeight:null
    };
  },
  methods: {
    clearValidate() {
      const type = this.form.type == 1 ? 1 : 0
      this.form.type = type;
      this.form.cbLogisticsCompany = null;
      this.form.consignee = null;
      this.form.phone = null;
      this.form.address = null;
      this.form.carCode = null;
      this.form.papers = null;
      this.form.pickupTime = null;
      this.$refs["form"].clearValidate();
    },
    showDialog(row) {
      this.dialogVisible = true;
      const tempData = JSON.parse(JSON.stringify(row));
      // if (this.deliveryType == 'deposit') {
      //   this.form.warehousId = tempData.applyWarehousId;
      // } else {
      //   this.form.warehousId = tempData.warehousId;
      // }
      // this.form.warehouse = tempData.name;
      // this.form.warehouseAddress = tempData.address;
      this.unit = tempData.unit
      if(this.source==0){
        this.form.holdId = tempData.holdId;
        this.form.appHoldId = null;
         this.form.deliveryWeight = tempData.canWeight;
        this.form.quantity = tempData.quantity;
        this.maxDeliveryWeight =  tempData.canWeight;
      }else{
        this.form.holdId = null;
        this.form.appHoldId = tempData.applyHoldId;
        this.form.deliveryWeight = tempData.weight;
        this.form.quantity = tempData.quantity;
         this.maxDeliveryWeight = tempData.weight;
      }     
      this.getCompanyOptions();
    },
    getCompanyOptions() {
      http.postFront(protocolFwd.param_selectAllCompany).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.companyOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goDelivery() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_deliveryWarehous.param = this.form;
          protocolFwd.param_deliveryWarehous.param.source = this.source;
          http.postFront(protocolFwd.param_deliveryWarehous).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.dialogVisible = false;
              this.$emit('reLoad');
              this.$confirm("查看提货信息", "申请成功", {
                distinguishCancelAndClose: true,
                confirmButtonText: "去看看",
                cancelButtonText: "取消"
              })
                .then(() => {
                  console.log(this.form.type )
                  if (this.form.type == 1) {
                    this.$router.push({
                      name: "sellerPickLogisticsBill"
                    })
                  } else if ( this.form.type == 0) {
                    this.$router.push({
                      name: "sellerPickSelfBill"
                    })
                  }
                  //  if (this.bsFlag == "B" && this.form.type == 1) {
                  //   this.$router.push({
                  //     name: "buyerCenterPickLogisticsBill"
                  //   })
                  // } else if (this.bsFlag == "B" && this.form.type == 0) {
                  //   this.$router.push({
                  //     name: "buyerCenterPickSelfBill"
                  //   })
                  // } else
                })
                .catch(action => {});
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    },
    handleClose() {
      this.form = {
        warehousId: null,
        type: 1,//提货类型0：自提1：物流
        cbLogisticsCompany: null,
        consignee: null,
        phone: null,
        address: null,
        carCode: null,
        papers: null,
        pickupTime: null,
        appHoldId:null,//申请持仓id
        deliveryWeight:null,// 交收重量
        holdId:null,// 持仓id
        quantity:null,// 数量
      };
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.maxDeliveryWeight = null
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.delivertip {
  font-weight: 700;
}
</style>


