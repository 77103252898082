import { render, staticRenderFns } from "./pickupGoods.vue?vue&type=template&id=9b93f3ce&scoped=true"
import script from "./pickupGoods.vue?vue&type=script&lang=js"
export * from "./pickupGoods.vue?vue&type=script&lang=js"
import style0 from "./pickupGoods.vue?vue&type=style&index=0&id=9b93f3ce&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b93f3ce",
  null
  
)

export default component.exports